import React from "react";

function InventoryTracking() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
    >
      <g fill="#fff">
        <path
          d="M15 512h120c8.291 0 15-6.709 15-15v-60c0-36.216-25.809-66.513-60-73.482V437c0 8.291-6.709 15-15 15s-15-6.709-15-15v-73.482C25.809 370.487 0 400.784 0 437v60c0 8.291 6.709 15 15 15z"
          data-original="#000000"
        ></path>
        <circle cx="75" cy="317" r="45" data-original="#000000"></circle>
        <path
          d="M497 0c-8.291 0-15 6.709-15 15v106h-30V46c0-8.291-6.709-15-15-15h-60c-8.291 0-15 6.709-15 15v75h-30V46c0-8.291-6.709-15-15-15h-60c-8.291 0-15 6.709-15 15v75h-15c-8.291 0-15 6.709-15 15s6.709 15 15 15h255v120h-30v-75c0-8.291-6.709-15-15-15h-60c-8.291 0-15 6.709-15 15v75h-30v-75c0-8.291-6.709-15-15-15h-60c-8.291 0-15 6.709-15 15v75h-15c-8.291 0-15 6.709-15 15s6.709 15 15 15h255v120h-30v-75c0-8.291-6.709-15-15-15h-60c-8.291 0-15 6.709-15 15v75h-30v-75c0-8.291-6.709-15-15-15h-60c-8.291 0-15 6.709-15 15v75h-15c-8.291 0-15 6.709-15 15s6.709 15 15 15h255v46c0 8.291 6.709 15 15 15s15-6.709 15-15V15c0-8.291-6.709-15-15-15zM0 151c0 49.629 40.371 90 90 90s90-40.371 90-90c0-15.485-4.288-29.848-11.204-42.585l-68.19 68.19c-5.859 5.859-15.351 5.86-21.211 0l-15-15c-5.859-5.859-5.859-15.352 0-21.211s15.352-5.859 21.211 0L90 144.789l60.209-60.209C134.225 70.08 113.231 61 90 61c-49.629 0-90 40.371-90 90z"
          data-original="#000000"
        ></path>
      </g>
    </svg>
  );
}

export default InventoryTracking;