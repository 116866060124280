import React from "react";

function Star() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path
        fill="#F7AC16"
        d="M497.666 185.695l-156.615-22.757-70.042-141.919c-6.126-12.414-23.887-12.42-30.018 0l-70.04 141.919-156.615 22.757C.639 187.686-4.862 204.574 5.06 214.244l113.327 110.468-26.754 155.982a16.737 16.737 0 0024.285 17.644L256 424.695c152.506 80.175 142.031 75.567 147.869 75.567 10.316 0 18.259-9.3 16.498-19.568l-26.753-155.982 113.328-110.468c9.911-9.663 4.432-26.556-9.276-28.549z"
      ></path>
      <path
        fill="#F2D422"
        d="M256.001 11.689a16.742 16.742 0 00-15.011 9.33l-70.04 141.919-156.614 22.757C.639 187.686-4.862 204.574 5.06 214.244l113.327 110.468-26.754 155.982a16.737 16.737 0 0024.285 17.644L256 424.695h.001V11.689z"
      ></path>
      <path
        fill="#F7AC16"
        d="M14.336 185.695C.639 187.686-4.862 204.574 5.06 214.244l113.327 110.468-26.754 155.982a16.737 16.737 0 0024.285 17.644L256 424.695c152.506 80.175 142.031 75.567 147.869 75.567a16.73 16.73 0 009.839-3.197L170.95 162.937 14.336 185.695z"
      ></path>
      <path
        fill="#F48B19"
        d="M178.558 305.445C57.164 187.114 62.382 193.456 60.753 187.319c-.663-2.465-.799-5.333-.078-8.358l-46.339 6.733C.639 187.685-4.862 204.573 5.06 214.243l113.327 110.468-26.754 155.983a16.737 16.737 0 0024.285 17.644l41.187-21.653a16.727 16.727 0 01-5.301-15.258l26.754-155.982z"
      ></path>
      <path
        fill="#F2D422"
        d="M5.06 214.244l113.327 110.468-26.754 155.982a16.737 16.737 0 0024.285 17.644L256 424.695c152.506 80.175 142.031 75.567 147.869 75.567 10.316 0 18.259-9.3 16.498-19.568l-26.753-155.982L.823 197.086c-1.957 7.015.002 13.032 4.237 17.158z"
      ></path>
      <path
        fill="#EFB616"
        d="M5.06 214.244l113.327 110.468-26.754 155.982a16.737 16.737 0 0024.285 17.644l41.187-21.653a16.727 16.727 0 01-5.301-15.258l26.754-155.982-77.878-75.913L.823 197.086c-1.957 7.015.002 13.032 4.237 17.158z"
      ></path>
      <path
        fill="#F7AC16"
        d="M91.633 480.694a16.737 16.737 0 0024.285 17.644L256 424.695c152.506 80.175 142.031 75.567 147.869 75.567 10.316 0 18.259-9.3 16.498-19.568l-26.753-155.982 113.328-110.468a16.743 16.743 0 004.235-17.157l-392.79 127.625-26.754 155.982z"
      ></path>
      <path
        fill="#F48B19"
        d="M91.633 480.694a16.737 16.737 0 0024.285 17.644l41.187-21.653a16.727 16.727 0 01-5.301-15.258l26.754-155.982-.219-.213-59.952 19.48-26.754 155.982z"
      ></path>
      <path
        fill="#F2D422"
        d="M115.919 498.338L256 424.695c152.506 80.175 142.031 75.567 147.869 75.567 10.316 0 18.259-9.3 16.498-19.568l-26.753-155.982 113.328-110.468c9.91-9.663 4.431-26.556-9.277-28.549L341.05 162.938 98.292 497.065a16.739 16.739 0 0017.627 1.273z"
      ></path>
      <path
        fill="#F7AC16"
        d="M511.178 197.086L256 279.998 98.292 497.065a16.737 16.737 0 0017.626 1.273L256 424.695c152.506 80.175 142.031 75.567 147.869 75.567 10.316 0 18.259-9.3 16.498-19.568l-26.753-155.982 113.328-110.468a16.743 16.743 0 004.236-17.158z"
      ></path>
      <path
        fill="#F2D422"
        d="M393.615 324.712L256 279.998 98.292 497.065a16.737 16.737 0 0017.626 1.273L256 424.695c152.506 80.175 142.031 75.567 147.869 75.567 10.316 0 18.259-9.3 16.498-19.568l-26.752-155.982z"
      ></path>
      <path
        fill="#F7AC16"
        d="M98.292 497.065a16.737 16.737 0 0017.626 1.273L256 424.695c152.506 80.175 142.031 75.567 147.869 75.567a16.73 16.73 0 009.839-3.197L256 279.998 98.292 497.065z"
      ></path>
      <path
        fill="#F2D422"
        d="M256.001 280l-.001-.002L98.292 497.065a16.737 16.737 0 0017.626 1.273L256 424.695h.001V280z"
      ></path>
      <path
        fill="#EFB616"
        d="M160.339 411.665l-62.047 85.4a16.737 16.737 0 0017.626 1.273l41.187-21.653a16.727 16.727 0 01-5.301-15.258l8.535-49.762z"
      ></path>
    </svg>
  );
}

export default Star;