import React, { useState } from 'react';
import './HamburgerMenu.css';

function HamburgerMenu({ isOpen, setIsOpen, onCloseFn }) {

  const toggleMenu = () => {
    if (isOpen) {
      onCloseFn()
    }
    
    setIsOpen(!isOpen)
  }

  return (
    <div className={`Hamburger_menu ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
      <div className="bar1"></div>
      <div className="bar2"></div>
      <div className="bar3"></div>
    </div>
  );
}

export default HamburgerMenu;
