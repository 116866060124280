import React from "react";

function SalesforceManagement() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      fillRule="evenodd"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
    >
      <path
        fill="#fff"
        d="M161 288h-1c-39 0-71-33-71-73v-78c0-20 15-40 37-40 3 0 7 1 10 1h17c15 0 24-2 28-6 2-3 2-7 2-12-1-2 0-3 1-5 1-1 3-2 5-2 27 1 44 19 44 47l-1 97c0 19-7 36-21 50-13 13-31 21-50 21zm-26-136c9 25 27 22 49 19 12-1 26-3 37 2l-1 44c0 32-27 59-59 59h-1c-33 0-59-27-59-61v-42l24-23c1-2 3-2 5-2 2 1 4 2 5 4zm179 360h-37V408c0-4-3-6-6-6-4 0-6 2-6 6v104h-62l-16-116 9-17 13 11c1 1 2 1 3 1h1c2 0 3-1 4-2l59-79c26 16 44 42 44 72v124c0 3-3 6-6 6zm-123 0h-56l15-111h26zm-68 0H61V408c0-4-3-6-6-6-4 0-6 2-6 6v104H12c-4 0-6-3-6-6V382c0-31 18-58 46-73l59 80c1 1 3 2 4 2h1c2 0 3 0 4-1l10-9 8 15zm17-139l24-21 23 19-10 18h-29zm-77-70c11-4 22-6 34-7l58 48-24 20c-1 1-1 1-2 1-1 1-3 2-3 4l-9 7zm52-8h93l-44 41zm110 1c14 0 28 3 40 9l-54 71-38-32zm126-2c-45 0-81-37-81-81 0-45 36-81 81-81 44 0 80 36 80 81 0 44-36 81-80 81zm-6-32c-13-3-22-14-22-28 0-3 3-6 6-6s6 3 6 6c0 7 4 13 10 15v-32c-11-2-22-8-22-26 0-13 9-24 22-27v-1c0-3 2-6 6-6 3 0 6 3 6 6v1c12 3 21 14 21 27 0 3-2 6-6 6-3 0-6-3-6-6 0-7-4-12-9-14v31c10 3 21 8 21 26 0 14-9 25-21 28 0 4-3 6-6 6-4 0-6-2-6-6zm12-13c5-2 9-8 9-15 0-8-3-11-9-13zm-12-72c-6 2-10 7-10 14 0 9 3 12 10 14zm100-55c-33 0-61-27-61-61s28-61 61-61c34 0 61 27 61 61s-27 61-61 61zm-6-24c-9-2-16-11-16-21 0-3 3-6 6-6 4 0 6 3 6 6s2 6 4 8V66c-7-3-16-7-16-21 0-10 7-18 16-21 1-3 3-5 6-5s6 2 6 5c9 3 16 11 16 21 0 3-3 6-6 6s-6-3-6-6-1-6-4-8v19c8 3 16 7 16 21 0 10-7 19-16 21 0 3-3 6-6 6s-5-3-6-6zm12-13c3-2 4-5 4-8 0-4-1-6-4-8zm-12-48c-2 2-4 5-4 8 0 4 1 7 4 8zm-130 85c-33 0-61-27-61-61s28-61 61-61c34 0 62 27 62 61s-28 61-62 61zm-6-24c-9-2-16-11-16-21 0-3 3-6 6-6 4 0 6 3 6 6s2 6 4 8V66c-7-3-16-7-16-21 0-10 7-18 16-21 1-3 3-5 6-5 4 0 6 2 6 5 10 3 16 11 16 21 0 3-2 6-6 6-3 0-6-3-6-6s-1-6-4-8v19c8 3 16 7 16 21 0 10-6 19-16 21 0 3-2 6-6 6-3 0-5-3-6-6zm12-13c3-2 4-5 4-8 0-4-1-6-4-8zm-12-48c-2 2-4 5-4 8 0 4 2 7 4 8z"
        data-original="#000000"
      ></path>
    </svg>
  );
}

export default SalesforceManagement;