import React from 'react'
import LandingPage from "./LandingPage"
import LandingPageMobile from "./LandingPageMobile"

function App() {
  const isMobile = window.innerWidth < 768

  return (
    <div className="App">
      {isMobile ? <LandingPageMobile /> : <LandingPage />}
    </div>
  )
}

export default App
