import React, { useEffect, useState } from 'react'
import './LandingPageMobile.css'
import Spreadsheet from './assets/svgs/Spreadsheet'
import Commission from './assets/svgs/Commission'
import SalesforceManagement from './assets/svgs/SalesforceManagement'
import DeliveryTruck from './assets/svgs/DeliveryTruck'
import SalesData from './assets/svgs/SalesData'
import InventoryTracking from './assets/svgs/InventoryTracking'
import Checkmark from './assets/svgs/Checkmark'
import httpClient from './httpClient'
import CircularProgress from '@mui/material/CircularProgress'
import HamburgerMenu from './components/HamburgerMenu'
import { HiOutlineArrowLongRight } from "react-icons/hi2"
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import Star from './assets/svgs/Star'

function LandingPageMobile
  () {
  const [loadingSendEmail, setLoadingSendEmail] = useState(false)
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY)
  const [isVisible, setIsVisible] = useState(true)
  const [popupIsActive, setPopupIsActive] = useState(false)
  const [selectedTopics, setSelectedTopics] = useState({
    "brandowner": 1,
    "salesforce": 1,
    "distribution": 1,
    "ordering": 1
  })
  const [selectedPricePeriod, setSelectedPricePeriod] = useState("annually")
  const [softwareOnlyPricing, setSoftwareOnlyPricing] = useState(false)
  const [selectedPricing, setSelectedPricing] = useState(null)
  const [email, setEmail] = useState("")
  const [emailSend, setEmailSend] = useState(false)
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [signInIsActive, setSignInIsActive] = useState(false)

  const star = require('./assets/icons/star.png')

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY

      // apply hide/appear effect
      setIsVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10)
      setPrevScrollPos(currentScrollPos)

      // give header a background when low enough
      const screenHeight = window.innerHeight
      const triggerHeight = screenHeight * 0.10 // 10% of the viewport height (same height as header)

      if (currentScrollPos > triggerHeight) {
        document.querySelector('header').classList.add('background')
      } else {
        document.querySelector('header').classList.remove('background')
      }
    }

    window.addEventListener('scroll', handleScroll)

    // Disable scrolling when popup is active
    if (popupIsActive | menuIsOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'visible'
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
      // Clean up by ensuring the overflow is reset when the component unmounts
      document.body.style.overflow = 'visible'
    }
  }, [prevScrollPos, popupIsActive, menuIsOpen])

  const problemsArray = [
    {
      "icon": <Spreadsheet />,
      "title": "Excel spreadsheet hell",
      "text": "Managing sales reports, tasks, and customer data across multiple Excel sheets can lead you into administrative chaos."
    },
    {
      "icon": <DeliveryTruck />,
      "title": "Distribution headaches",
      "text": "For small brand owners, handling deliveries in-house is time-consuming. Packaging parcels or diverting your salesforce to make deliveries, instead of selling, is a major hassle."
    },
    {
      "icon": <Commission />,
      "title": "Commission-based sales incentives",
      "text": "Implementing a recurring commission-based reward system for your salesforce is challenging without consistent sales data."
    },
    {
      "icon": <SalesData />,
      "title": "Lack of sales data",
      "text": "Wholesalers often fail to provide transparent sales data, or when they do, integrating this data into your system requires significant effort to maintain."
    },
    {
      "icon": <SalesforceManagement />,
      "title": "Unstructured salesforce management",
      "text": "It's unclear how the salesforce is performing and challenging to guide them effectively without data-driven insights."
    },
    {
      "icon": <InventoryTracking />,
      "title": "Inaccurate inventory tracking",
      "text": "Stock numbers are frequently inaccurate, necessitating manual counts for accounting purposes every time accurate inventory data is needed."
    },
  ]

  const screenshots = {
    "brandowner": [
      "orderManagement.png",
      "salesforceManagement.png",
      "warehouseManagement.png"
    ],
    "salesforce": [
      "salesPipes.png",
      "activities.png",
      "stockTracking.png",
    ],
    "distribution": [
      "distribution_schema.png"
    ],
    "ordering": [
      "ordering_app.png",
      "ordering_app.png",
    ]
  }

  const pricing = {
    "software-only": [
      {
        "title": "LONE WOLF",
        "price_month": 0,
        "price_anual": 0,
        "features": [
          { "description": "For 1 user", "detail": "" },
          { "description": "No API connections", "detail": "" },
          { "description": "Limited to 10 customers", "detail": "" },
          { "description": "Service level: email", "detail": "" },
        ]
      },
      {
        "title": "PACK LEADER",
        "price_month": 45,
        "price_anual": 39,
        "features": [
          { "description": "For 1 user", "detail": "(€34/extra)" },
          { "description": "Connect your distribution partner/webshop", "detail": "" },
          { "description": "Unlimited", "detail": "" },
          { "description": "Service level: Whatsapp", "detail": "" },
        ]
      },
      {
        "title": "TROOP",
        "price_month": 329,
        "price_anual": 299,
        "features": [
          { "description": "For 10 users", "detail": "(€29/extra)" },
          { "description": "Connect your distribution partner/webshop", "detail": "" },
          { "description": "Unlimited", "detail": "" },
          { "description": "Service level: Whatsapp+", "detail": "" },
        ]
      },
    ],
    "with-distribution": [
      {
        "title": "starter",
        "price_month": 109,
        "price_anual": 99,
        "features": [
          { "description": "Warehouse space: 3 shelves", "detail": "" },
          { "description": "Users: 1", "detail": "(€34/extra)" },
          { "description": "Shipping price: custom", "detail": "" },
          { "description": "Service level: Whatsapp", "detail": "" },
        ]
      },
      {
        "title": "professional",
        "price_month": 159,
        "price_anual": 149,
        "features": [
          { "description": "Warehouse space: 3 pallets", "detail": "(€18/extra)" },
          { "description": "Users: 2", "detail": "(€34/extra)" },
          { "description": "Shipping price: custom", "detail": "" },
          { "description": "Service level: Whatsapp+", "detail": "" },
        ]
      },
      {
        "title": "enterprise",
        "price_month": 1499,
        "price_anual": 1399,
        "features": [
          { "description": "Everything from professional", "detail": "" },
          { "description": "White labeled ordering app", "detail": "" },
          { "description": "Webshop management & creation", "detail": "" },
          { "description": "Custom development", "detail": "" },
        ]
      },
    ]
  }

  const handleClickSolution = (e, sectionID, number) => {
    const prevState = { ...selectedTopics }
    prevState[sectionID] = number
    setSelectedTopics(prevState)
  }

  const handleSendEmail = async () => {
    setLoadingSendEmail(true)
    const data = {
      email, selectedPricing
    }
    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/website/email-subscription`, data)

    setEmailSend(true)
    setLoadingSendEmail(false)
  }

  const handleCloseFnMenu = () => {
    setSignInIsActive(false)
  }

  return (
    <div className='landing_page__container' id='landingpage--mobile'>

      <div className={`menu_popup ${menuIsOpen ? 'active' : ''}`}>
        <div className='menu_popup__content'>
          <div className='menu_bar__buttons'>
            <button className='button_no_fill' onClick={() => setSignInIsActive(true)}>Sign in</button>
            <button className='button_fill' onClick={() => { setPopupIsActive(true); setSelectedPricing(null); setMenuIsOpen(false) }}>Book a demo</button>
          </div>
          {
            signInIsActive ?
              <div className='signin_options'>
                <a className='signin_option' href='https://order.stoqup.be'>
                  <h4>ORDERING<br />APP</h4>
                </a>

                <a className='signin_option' href='https://dashboard.stoqup.be'>
                  <h4>BRAND OWNER<br />DASHBOARDS</h4>
                </a>
              </div>
              :
              <ul className='menu_bar'>
                <a href='#brandowner-dashboard' onClick={() => setMenuIsOpen(false)}><li>Our Solution</li></a>
                <a href='#pricing' onClick={() => setMenuIsOpen(false)}><li>Pricing</li></a>
                <a href='#contact' onClick={() => setMenuIsOpen(false)}><li>Contact</li></a>
              </ul>
          }
        </div>
      </div>

      <div className={`popout_container ${popupIsActive ? "active" : ""}`}>
        <div className='popout_box'>
          <div className='popout_close' onClick={() => setPopupIsActive(false)}>x</div>

          <div className='popout_content'>
            <h4>Share your email and expect a friendly note from us shortly!</h4>

            <input placeholder='Enter you email address' value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
            <button onClick={handleSendEmail}>
              {
                loadingSendEmail ?
                  <CircularProgress color='inherit' size={'1.5rem'} /> :
                  <>
                    {
                      emailSend ? "Thank you! We will be in contact shortly." : "Send"
                    }
                  </>
              }
            </button>
          </div>
        </div>
      </div>

      <header className={!isVisible ? 'hidden' : ''}>
        <div className='header_content'>
          <a href='#main-page' onClick={() => { setMenuIsOpen(false); setSignInIsActive(false) }}><img src='./img/Stoqup_logo_black.png' alt='stoqup logo' /></a>
          <HamburgerMenu isOpen={menuIsOpen} setIsOpen={setMenuIsOpen} onCloseFn={handleCloseFnMenu} />
        </div>
      </header>

      <section className='landing_page__page' id='main-page'>
        <div className='radial_gradient'></div>
        <h1>STREAMLINE YOUR SALES, DATA AND DELIVERIES</h1>
        <p>An intuitive CRM designed for hospitality brands, enhancing efficiency in sales management, data analysis, and product delivery. Helping you grow your brand!</p>
        <div className='buttons'>
          <a className='button_fill' onClick={() => { setPopupIsActive(true); setSelectedPricing(null) }}>Book a demo</a>
          <a href='#problems' className='button_no_fill'>Tell me more</a>
        </div>
        <p>Already loved and trusted<br /> by these amazing brands</p>
        <div className='brands'>
          <a href='https://opius.be' id='opius' target="_blank"><img src='./img/brands/opius.jpg' /></a>
          <a href='https://hanaspirits.com' target="_blank"><img src='./img/brands/hana.png' /></a>
          <a href='https://gingerlo.com' id='gingerlo' target="_blank"><img src='./img/brands/gingerlo.png' /></a>
          <a href='https://nomussmixers.com' target="_blank"><img src='./img/brands/nomuss.avif' /></a>
          <a href='https://heyudrinks.com' id='heyu' target="_blank"><img src='./img/brands/heyu.png' /></a>
          <a href='https://pomton.com' id='pomton' target="_blank"><img src='./img/brands/pomton.png' /></a>
        </div>
      </section>

      <section className='problems__page' id='problems'>
        <h2>The problems we solve</h2>
        <div className='problems'>
          {
            problemsArray.map((topic, index) => {

              return (
                <div className='problem__container' key={index}>
                  {topic.icon}
                  <h4>{topic.title}</h4>
                  <p>{topic.text}</p>
                </div>
              )
            })
          }
        </div>
      </section>

      <section className='our_solution__page' id='brandowner-dashboard'>
        <h2>Our solution</h2>
        <div className='solution__container'>
          <div className='radial_gradient'></div>

          <h3>Brand Owner Dashboard</h3>
          <div className='solution_topics'>
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
            >
              <SwiperSlide>
                <div className={`topic`} onClick={(e) => handleClickSolution(e, "brandowner", 1)}>
                  <div className='title'><Checkmark /><h4>Order Management</h4></div>
                  <p>Manage your B2C & B2B orders all in one place. Connect your Webshop & Create your suppliers.</p>
                  <img src={`./img/screenshots/${screenshots.brandowner[0]}`} className='screenshot' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={`topic`} onClick={(e) => handleClickSolution(e, "brandowner", 2)}>
                  <div className='title'><Checkmark /><h4>Salesforce Management</h4></div>
                  <p>Track your salesforce performance in real time with KPI’s.</p>
                  <img src={`./img/screenshots/${screenshots.brandowner[1]}`} className='screenshot' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={`topic`} onClick={(e) => handleClickSolution(e, "brandowner", 3)}>
                  <div className='title'><Checkmark /><h4>Warehouse Management</h4></div>
                  <p>An overview of all your warehouse spaces and stock in one place.</p>
                  <img src={`./img/screenshots/${screenshots.brandowner[2]}`} className='screenshot' />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className='swipe'>Swipe <HiOutlineArrowLongRight /></div>
        </div>
      </section>

      <section className='our_solution__page' id='salesforce-dashboard'>
        <h2>Our solution</h2>
        <div className='solution__container'>
          <div className='radial_gradient'></div>

          <h3>Salesforce Dashboard</h3>
          <div className='solution_topics'>
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
            >
              <SwiperSlide>
                <div className={`topic`} onClick={(e) => handleClickSolution(e, "salesforce", 1)}>
                  <div className='title'><Checkmark /><h4>Sales Pipes</h4></div>
                  <p>Automated sales pipes for effective prospect management.</p>
                  <img src={`./img/screenshots/${screenshots.salesforce[0]}`} className='screenshot' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={`topic`} onClick={(e) => handleClickSolution(e, "salesforce", 2)}>
                  <div className='title'><Checkmark /><h4>Activities</h4></div>
                  <p>Register your visits, task, notes, etc. for effective sales management & follow up.</p>
                  <img src={`./img/screenshots/${screenshots.salesforce[1]}`} className='screenshot' />

                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={`topic`} onClick={(e) => handleClickSolution(e, "salesforce", 3)}>
                  <div className='title'><Checkmark /><h4>Stock Tracking</h4></div>
                  <p>Track personal salesforce’s stock, sampling and free goods.</p>
                  <img src={`./img/screenshots/${screenshots.salesforce[2]}`} className='screenshot' />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className='swipe'>Swipe <HiOutlineArrowLongRight /></div>
        </div>
      </section>

      <section className='our_solution__page' id='distribution'>
        <h2>Our solution</h2>
        <div className='solution__container'>
          <div className='radial_gradient'></div>

          <h3>Distribution & Warehousing</h3>
          <div className='solution_topics'>
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
            >
              <SwiperSlide>
                <div className={`topic`} onClick={(e) => handleClickSolution(e, "distribution", 1)}>
                  <div className='title'><Checkmark /><h4>Shipping & Warehousing Services</h4></div>
                  <p>Automated sales pipes for effective prospect management.</p>
                  <img src={`./img/screenshots/${screenshots.distribution[0]}`} className='screenshot' />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={`topic`} onClick={(e) => handleClickSolution(e, "distribution", 2)}>
                  <div className='title'><Checkmark /><h4>Or Connect Your Own Partner</h4></div>
                  <p>We have an API integration with many logistic partners and new ones can be created.</p>
                  <div className='content'>
                    <img src='./img/partners/webship.svg' />
                    <img src='./img/partners/geru.png' />
                    <img src='./img/partners/shopwedo.png' />
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className='swipe'>Swipe <HiOutlineArrowLongRight /></div>
        </div>
      </section >


      <section className='our_solution__page' id='ordering-app'>
        <h2>Our solution</h2>
        <div className='solution__container'>
          <div className='radial_gradient'></div>

          <h3>Ordering App</h3>
          <div className='content'>
            <div className='solution_topics'>
              <div className={`topic`} onClick={(e) => handleClickSolution(e, "ordering", 1)}>
                <div className='title'><Checkmark /><h4>Free app for your customers</h4></div>
                <p>Offer our app to your customers for free and have a structured inflow of follow up orders and stay in control of your sales data</p>
              </div>
              <div className={`topic`} onClick={(e) => handleClickSolution(e, "ordering", 2)}>
                <div className='title'><Checkmark /><h4>With your customer needs in mind</h4></div>
                <p>
                  Did you know that the average hospitality business has 8 different suppliers? This is holding them back to use each supplier’s online order platform. So we have designed our app to be the one stop shop for hospitality ordering.
                </p>
                <p>Users can:</p>
                <ul>
                  <li>Add all their suppliers</li>
                  <li>Order any product from our database</li>
                  <li>Or even add their own products</li>
                  <li>Create lists</li>
                </ul>
              </div>
              <div className='content_image'>
                <img src={`./img/screenshots/${screenshots.ordering[selectedTopics.ordering - 1]}`} className='screenshot' />
              </div>
            </div>
          </div>


        </div>
      </section>

      <section className='our_solution__page' id='testimonials-section'>
        <h2>Hear what our customers<br /> have to say</h2>
        <div className='testimonials'>
          <div className='testimonial'>
            <div className='testimonial_stars'>
              <Star />
              <Star />
              <Star />
              <Star />
              <Star />
            </div>
            <p>
              STOQUP has proven to be a key tool in the growth & succes of our brand. It allowed us to start supplying our (horeca) customers, which couldn’t order our product at their local drinkcenter. Additionally the platform enables us to track sales & efficiently manage our salesforce,
              all while managing stock, shipments & overall deal cycles. Setting goals, calculating commissions & following up progress has never been easier.
            </p>
            <div className='testimonial_photo_and_name'>
              <img src='./img/brands/lucas_leander.png' />
              <div className='name_tags'>
                <h4>Lucas & Leander</h4>
                <p>Opius Distillery</p>
              </div>
            </div>
          </div>
          <div className='testimonial'>
            <div className='testimonial_stars'>
              <Star />
              <Star />
              <Star />
              <Star />
              <Star />
            </div>
            <p>
              STOQUP has helped us with an efficient and user-friendly CRM solution. The app simplifies the tracking of visit registrations, orders, and customer profiles, allowing our team to operate quickly and accurately. STOQUP is easily adaptable to the specific needs of our company and offers reliability and security in data management.
            </p>
            <div className='testimonial_photo_and_name'>
              <img src='./img/brands/nomuss_eigenaars.png' />
              <div className='name_tags'>
                <h4>Edouard, Henri, <br />Florien & Lennert</h4>
                <p>CU Brands</p>
              </div>
            </div>
          </div>
          <div className='testimonial'>
            <div className='testimonial_stars'>
              <Star />
              <Star />
              <Star />
              <Star />
              <Star />
            </div>
            <p>
              Great opportunity to have all your logistical tasks carried out by professionals. In addition, you have access to your own online dashboard where you have a nice overview of customers, orders, inventory, etc. I also really like the way everything is followed up on, very well and quickly. I strongly believe in STOQUP and see them growing in a positive way.
            </p>
            <div className='testimonial_photo_and_name'>
              <img src='./img/brands/evelien.png' />
              <div className='name_tags'>
                <h4>Evelien</h4>
                <p>Gingerlo</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='pricing__page' id='pricing'>
        <h2>Pricing</h2>
        <div className='pricing_container'>
          <div className='period_box_container'>
            <div className='period_box'>
              <p className={`${selectedPricePeriod == 'monthly' ? 'selected' : ''}`} onClick={() => setSelectedPricePeriod('monthly')}>Monthly</p>
              <p className={`${selectedPricePeriod == 'annually' ? 'selected' : ''}`} onClick={() => setSelectedPricePeriod('annually')}>Annualy</p>
            </div>
            <div className='pricing_types'>
              <p className={`${softwareOnlyPricing ? '' : 'selected'}`} onClick={() => setSoftwareOnlyPricing(!softwareOnlyPricing)}>With distribution</p>
              <p className={`${softwareOnlyPricing ? 'selected' : ''}`} onClick={() => setSoftwareOnlyPricing(!softwareOnlyPricing)}>Software only</p>
            </div>
          </div>

          {
            <div className='price_options_container'>
              {
                pricing[softwareOnlyPricing ? 'software-only' : 'with-distribution'].map((price, index) => (
                  <div className='price_option' key={index}>
                    <div className='radial_gradient'></div>
                    {
                      index == 1 &&
                      <div className='best_value'><p>Popular</p></div>
                    }

                    <div className='title'>{price.title}</div>

                    <div className='price_wrapper'>
                      {
                        selectedPricePeriod == "annually" ?
                          <>
                            <div className='price'>
                              €{price.price_anual}<div className='period'>/month</div>
                            </div>
                            <div className='billing_info'>billed yearly</div>
                          </> :
                          <>
                            <div className='price'>
                              €{price.price_month}<div className='period'>/month</div>
                            </div>
                            <div className='billing_info'>billed monthly</div>
                          </>
                      }
                    </div>

                    <div className='features'>
                      {
                        price.features.map((feature, index) => (
                          <div className='feature' key={index}>
                            <Checkmark />
                            <div className='feature--description'>{feature.description} <p className='feature--detail'>{feature.detail}</p></div>
                          </div>
                        ))
                      }
                    </div>

                    <button onClick={() => { setPopupIsActive(true); setSelectedPricing(price.title) }}>Select Plan</button>

                  </div>
                ))
              }
            </div>
          }
        </div>
      </section>

      <footer id='contact'>
        <img src='./img/Stoqup_logo_black.png' alt='stoqup logo' />
        <div className='contact_info'>
          <a href='mailto:hello@stoqup.eu?SUBJECT=I want to get in touch!'><p>hello@stoqup.eu</p></a>
          <p>+32 479 71 86 51</p>
        </div>
        <div className='address_info'>
          <p>hundelgemsesteenweg 296a</p>
          <p>9820 merelbeke (Belgium)</p>
        </div>
      </footer>

    </div >
  )
}

export default LandingPageMobile
