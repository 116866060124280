import React from "react";

function Spreadsheet() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 26 26"
    >
      <path
        fill="#fff"
        d="M25.162 3H16v2.984h3.031v2.031H16V10h3v2h-3v2h3v2h-3v2h3v2h-3v3h9.162c.461 0 .838-.391.838-.87V3.87c0-.479-.377-.87-.838-.87zM24 20h-4v-2h4v2zm0-4h-4v-2h4v2zm0-4h-4v-2h4v2zm0-4h-4V6h4v2zM0 2.889v20.223L15 26V0L0 2.889zM9.488 18.08l-1.745-3.299c-.066-.123-.134-.349-.205-.678h-.027a5.135 5.135 0 01-.234.707l-1.751 3.27H2.807l3.228-5.064-2.953-5.065h2.776l1.448 3.037c.113.24.214.525.304.854h.028c.057-.198.163-.492.318-.883l1.61-3.009h2.542l-3.037 5.022 3.122 5.107-2.705.001z"
        data-original="#030104"
      ></path>
    </svg>
  );
}

export default Spreadsheet;