import React from "react";

function Checkmark() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 682.667 682.667"
    >
      <defs>
        <clipPath id="b" clipPathUnits="userSpaceOnUse">
          <path
            fill="#79c7bc"
            d="M0 512h512V0H0z"
            data-original="#000000"
          ></path>
        </clipPath>
      </defs>
      <mask id="a">
        <rect
          width="100%"
          height="100%"
          fill="#fff"
          data-original="#ffffff"
        ></rect>
      </mask>
      <g mask="url(#a)">
        <path
          fill="none"
          stroke="#79c7bc"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="53.333"
          d="M473.365 251.884L294.467 430.782l-85.164-85.162"
          data-original="#000000"
        ></path>
        <g
          clipPath="url(#b)"
          transform="matrix(1.33333 0 0 -1.33333 0 682.667)"
        >
          <path
            fill="none"
            stroke="#79c7bc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="40"
            d="M492 256c0-130.339-105.661-236-236-236S20 125.661 20 256s105.661 236 236 236 236-105.661 236-236z"
            data-original="#000000"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Checkmark;